<template>
    <div class="full-height-layout fill">
        <mercur-card class="full-height-layout fill form mx-4 mb-3 px-4">
            <h1>
                <span v-if="isEdit">Edit user</span>
                <span v-else>Add user</span>
            </h1>
            <div class="row">
                <div class="col-6">
                    <mercur-input
                        v-model="$v.form.firstName.$model"
                        required
                        :disabled="loading"
                        :class="{'form-invalid': $v.form.firstName.$error}">
                        First name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.firstName.required">First name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.form.lastName.$model"
                        required
                        :disabled="loading"
                        :class="{'form-invalid': $v.form.lastName.$error}">
                        Last name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.lastName.required">Last name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.form.email.$model"
                        required
                        :disabled="loading"
                        :class="{'form-invalid': $v.form.email.$error}">
                        Email address
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.email.email">Email address is not valid</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.form.phoneLandLine.$model"
                        type="tel"
                        :disabled="loading"
                        :class="{'form-invalid': $v.form.phoneLandLine.$error}">
                        Phone (landline)
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.form.phoneMobile.$model"
                        type="tel"
                        :disabled="loading"
                        :class="{'form-invalid': $v.form.phoneMobile.$error}">
                        Phone (mobile)
                    </mercur-input>
                </div>
                <div class="col-6 flex align-items-center">
                    <v-select
                        v-model="$v.form.supplierAdditionalInfo.roles.$model"
                        placeholder="Roles"
                        :multiple="true"
                        :taggable="true"
                        :select-on-tab="true"
                        @keypress.enter.stop.prevent="() => {}"
                        :disabled="loading"
                    >
                        <div slot="no-options"><small>Type a role and press enter to add.</small></div>
                    </v-select>
                </div>
            </div>
            <hr>
            <h2 class="font-weight-normal">
                Notification settings
            </h2>
            <div class="row mt-2">
                <div class="col-12">
                    <table>
                        <tbody v-for="(notificationSection, key) in notificationSections" :key="key">
                            <tr :key="`${key}_header`">
                                <td><strong>{{notificationSection.sectionTitle}}</strong></td>
                                <td class="text-center"><span v-if="key === 0">Email:</span></td>
                            </tr>
                            <tr v-for="(notification, notificationKey) in notificationSection.sectionItems" :key="notificationKey">
                                <td class="pl-5 pr-5">
                                    {{parsePlaceholders(notification.title)}}
                                </td>
                                <td class="text-center">
                                    <mercur-checkbox
                                        v-model="emailNotifications"
                                        :value="notification.value"
                                    ></mercur-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"><hr /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="text-right">
                <mercur-button @click="submit" :disabled="$v.$invalid || loading" class="btn btn-raised btn-yellow text-uppercase">Submit</mercur-button>
            </div>
        </mercur-card>
    </div>
</template>
<script>
import { email, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import CONFIG from '@root/config'
import { stripProperties } from '@/components/utils/Utils'
import vSelect from 'vue-select'
import { getDeepProperty } from '../../../components/utils/Utils'

export default {
    name: 'SupplierUserAdd',
    components: { vSelect },
    mixins: [ validationMixin ],
    data () {
        return {
            loading: false,
            action: null,
            supplierDetails: {},
            originalData: {},
            form: {
                lastName: null,
                firstName: null,
                phoneLandLine: null,
                phoneMobile: null,
                email: null,
                supplierAdditionalInfo: {
                    roles: [],
                    notificationConfiguration: {
                        email: [],
                    },
                },
            },
        }
    },
    validations: {
        form: {
            firstName: { required },
            lastName: { required },
            phoneLandLine: {},
            phoneMobile: {},
            email: {
                email,
                required,
            },
            supplierAdditionalInfo: {
                roles: {},
                notificationConfiguration: {
                    email: {},
                },
            },
        },
    },
    computed: {
        isEdit () {
            return !!this.$route.params.userId
        },
        emailNotifications: {
            get () {
                return getDeepProperty('form.supplierAdditionalInfo.notificationConfiguration.email', this, [])
            },
            set (value) {
                this.$set(this.form.supplierAdditionalInfo.notificationConfiguration, 'email', value)
            },
        },
        notificationSections () {
            return CONFIG.NOTIFICATIONS.SUPPLIER_ACCOUNT
        },
    },

    methods: {
        parsePlaceholders (input) {
            Object.entries(this.supplierDetails).forEach(([tokenName, value]) => {
                input = input.replace(`{{${tokenName}}}`, value)
            })
            return input
        },
        submit () {
            const data = stripProperties(this.form)
            data.supplierAdditionalInfo.supplierId = this.supplierDetails.supplierId

            if (this.isEdit) {
                this.action = CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.UPDATE.replace('{accountId}', this.$route.params.userId)
            }

            this.addJob(this.action)
            this.loading = true
            this.$api.post(this.action, data).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `User data was saved`,
                })

                setTimeout(() => {
                    this.$router.push({
                        name: 'SupplierUsers',
                        params: {
                            supplierId: this.supplierId,
                        },
                    })
                }, 100)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving user data failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(this.action)
            })
        },
        fetchUser () {
            if (this.isEdit === false) {
                return
            }

            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.GET.replace('{accountId}', this.$route.params.userId)
            this.loading = true
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                this.originalData = data.data
                if (!this.originalData.supplierAdditionalInfo) {
                    this.originalData.supplierAdditionalInfo = {
                        email: [],
                    }
                }
                if (Array.isArray(this.originalData.supplierAdditionalInfo.notificationConfiguration)) {
                    this.originalData.supplierAdditionalInfo.notificationConfiguration = {
                        email: [],
                    }
                }
                this.form = JSON.parse(JSON.stringify(this.originalData))
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },
    created () {
        this.supplierData.then(supplierDetails => {
            this.supplierDetails = supplierDetails
            if (!this.isEdit) {
                this.action = CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.CREATE.replace('{organisationId}', supplierDetails.organisationId)
            }
        })
        this.fetchUser()
    },
}
</script>

<style lang="scss" scoped>
    .form {
        max-width: 900px;
        &__content {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
</style>
